var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("console.dashboard.projectListCard.title")) +
              " "
          ),
          _c(
            "v-icon",
            {
              attrs: { right: "" },
              on: {
                click: function ($event) {
                  return _vm.refreshProjects()
                },
              },
            },
            [_vm._v("mdi-refresh")]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          "sort-desc": "",
          headers: _vm.headers,
          items: _vm.projectItems,
          "sort-by": "last_modified",
        },
        scopedSlots: _vm._u([
          {
            key: "item.numTemplates",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_vm._v(_vm._s(item.numTemplates))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(item.templates.join("<br>")),
                      },
                    }),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.last_modified",
            fn: function ({ item }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.standardDateTimeFormat(item.last_modified)) +
                    " "
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { left: "", "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { small: "" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("mdi-dots-vertical")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.action = "copy"
                                    _vm.actionProjectName = item.name
                                    _vm.$refs.dialogCopyOrRenameProject.show({
                                      projectName: item.name,
                                    })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", left: "" } },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "console.dashboard.projectListCard.actions.duplicate"
                                      )
                                    )
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.actionProjectName = item.name
                                    _vm.$refs.dialogDeleteProject.show({
                                      projectName: item.name,
                                    })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      left: "",
                                      color: "error",
                                    },
                                  },
                                  [_vm._v("mdi-delete")]
                                ),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "red--text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "console.dashboard.projectListCard.actions.delete"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("tutti-dialog", {
        ref: "dialogCopyOrRenameProject",
        attrs: {
          "max-width": "600",
          buttons: [
            {
              label: "Cancel",
              attrs: {
                text: true,
                color: "grey",
              },
              on: {
                click: () => {
                  this.$refs.dialogCopyOrRenameProject.hide()
                },
              },
            },
            {
              label: "OK",
              attrs: {
                text: true,
                color: "indigo darken-2",
              },
              on: {
                click: () => {
                  this.copyOrRenameProject(
                    _vm.action,
                    _vm.actionProjectName,
                    _vm.newProjectName
                  )
                  this.$refs.dialogCopyOrRenameProject.hide()
                },
              },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm.action === "copy"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "console.dashboard.projectListCard.copyOrRenameProjectDialog.title.copy",
                              { projectName: _vm.actionProjectName }
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm.action === "rename"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "console.dashboard.projectListCard.copyOrRenameProjectDialog.title.rename",
                              { projectName: _vm.actionProjectName }
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-card-text",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$t(
                          "console.dashboard.projectListCard.copyOrRenameProjectDialog.textFieldLabel"
                        ),
                      },
                      model: {
                        value: _vm.newProjectName,
                        callback: function ($$v) {
                          _vm.newProjectName = $$v
                        },
                        expression: "newProjectName",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogDeleteProject",
        on: { confirm: _vm.deleteProject },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function ({ params }) {
              return [
                _c("v-icon", { attrs: { left: "", color: "error" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "console.dashboard.projectListCard.deleteProjectDialog.title",
                      { projectName: params.projectName }
                    )
                  ) + " "
                ),
              ]
            },
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "console.dashboard.projectListCard.deleteProjectDialog.body"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }