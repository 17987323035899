<template>
    <v-main class="mt-10 grey lighten-4">
        <v-row justify="center">
            <v-col cols="9">
                <project-card :projectName="projectName" :client="client"></project-card>
            </v-col>
            <v-col cols="9">
                <project-list-card :client="client" />
            </v-col>
        </v-row>
    </v-main>
</template>
<script>
import ProjectCard from './ProjectCard'
import ProjectListCard from './ProjectListCard'

export default {
    components: {
        ProjectCard,
        ProjectListCard,
    },
    props: [ 'projectName', 'client' ],
}
</script>
