<template>
    <v-card>
        <v-card-title>
            {{ $t('console.dashboard.projectListCard.title') }}
            <v-icon right @click="refreshProjects()">mdi-refresh</v-icon>
        </v-card-title>
        <v-data-table
            dense
            sort-desc
            :headers="headers"
            :items="projectItems"
            sort-by="last_modified"
        >
            <template v-slot:item.numTemplates="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.numTemplates }}</span>
                    </template>
                    <span v-html="item.templates.join('<br>')" />
                </v-tooltip>
            </template>
            <template v-slot:item.last_modified="{ item }">
                {{ standardDateTimeFormat(item.last_modified) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="text-right">
                    <v-menu left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-on="on" v-bind="attrs">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list dense>
                            <v-list-item @click="action = 'copy'; actionProjectName = item.name; $refs.dialogCopyOrRenameProject.show({ projectName: item.name })">
                                <v-icon small left>mdi-content-copy</v-icon>
                                <v-list-item-title>{{ $t('console.dashboard.projectListCard.actions.duplicate') }}</v-list-item-title>
                            </v-list-item>
                            <!--<v-list-item @click="action = 'rename'; actionProjectName = item.name; $refs.dialogCopyOrRenameProject.show({ projectName: item.name })">
                                <v-icon small left>mdi-pencil</v-icon>
                                <v-list-item-title>{{ $t('console.dashboard.projectListCard.actions.rename') }}</v-list-item-title>
                            </v-list-item>-->
                            <v-list-item @click="actionProjectName = item.name; $refs.dialogDeleteProject.show({ projectName: item.name })">
                                <v-icon small left color="error">mdi-delete</v-icon>
                                <v-list-item-title class="red--text">{{ $t('console.dashboard.projectListCard.actions.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>

        <tutti-dialog
            ref="dialogCopyOrRenameProject"
            max-width="600"
            :buttons="[
                {
                    label: 'Cancel',
                    attrs: {
                        text: true,
                        color: 'grey'
                    },
                    on: {
                        click: () => { this.$refs.dialogCopyOrRenameProject.hide() }
                    }
                },
                {
                    label: 'OK',
                    attrs: {
                        text: true,
                        color: 'indigo darken-2'
                    },
                    on: {
                        click: () => {
                            this.copyOrRenameProject(action, actionProjectName, newProjectName);
                            this.$refs.dialogCopyOrRenameProject.hide();
                        }
                    }
                }
            ]"
        >
            <template v-slot:title>
                <span v-if="action === 'copy'">
                    {{ $t('console.dashboard.projectListCard.copyOrRenameProjectDialog.title.copy', { projectName: actionProjectName }) }}
                </span>
                <span v-else-if="action === 'rename'">
                    {{ $t('console.dashboard.projectListCard.copyOrRenameProjectDialog.title.rename', { projectName: actionProjectName }) }}
                </span>
            </template>
            <template v-slot:body>
                <v-card-text>
                    <v-text-field
                        v-model="newProjectName"
                        :label="$t('console.dashboard.projectListCard.copyOrRenameProjectDialog.textFieldLabel')"
                    ></v-text-field>
                </v-card-text>
            </template>
        </tutti-dialog>

        <tutti-dialog-alert
            ref="dialogDeleteProject"
            @confirm="deleteProject"
        >
            <template v-slot:title="{ params }">
                <v-icon left color="error">mdi-alert</v-icon>{{ $t('console.dashboard.projectListCard.deleteProjectDialog.title', { projectName: params.projectName }) }}
            </template>
            <template v-slot:body>
                <v-card-text>
                    {{ $t('console.dashboard.projectListCard.deleteProjectDialog.body') }}
                </v-card-text>
            </template>
        </tutti-dialog-alert>
    </v-card>
</template>
<script>
import { standardDateTimeFormat } from '@/lib/utils'
import TuttiDialog from '@/components/ui/TuttiDialog'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'

export default {
    components: {
        TuttiDialog,
        TuttiDialogAlert,
    },
    data() {
        return {
            headers: [
                { value: 'name', text: this.$t('console.dashboard.projectListCard.tableHeaders.name') },
                { value: 'numTemplates', text: this.$t('console.dashboard.projectListCard.tableHeaders.numTemplates') },
                //{ value: 'description', text: this.$t('console.dashboard.projectListCard.tableHeaders.description') },
                { value: 'last_modified', text: this.$t('console.dashboard.projectListCard.tableHeaders.last_modified') },
                { value: 'actions', text: '' },
            ],
            projectItems: [],
            action: '',
            actionProjectName: '',
            newProjectName: ''
        }
    },
    props: ['client'],
    methods: {
        standardDateTimeFormat,
        async copyOrRenameProject(action, projectName, newProjectName) {
            if(action === 'copy')
                await this.client.resource.copyProject({ project_name_from: projectName, project_name_to: newProjectName });
            else if(action === 'rename')
                await this.client.resource.renameProject({ project_name_from: projectName, project_name_to: newProjectName });
            window.location.reload();
        },
        async deleteProject({ projectName }) {
            await this.client.resource.deleteProject({ project_name: projectName });
            window.location.reload();
        },
        async refreshProjects() {
            let projects = await this.client.resource.listProjects();
            for(const i in projects) {
                let project = projects[i];
                project.templates = await this.client.resource.listTemplates({ project_name: project.name });
                project.numTemplates = project.templates.length;
            }
            this.projectItems = projects;
        },
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            await this.refreshProjects();
        });
    }
}
</script>
