<template>
    <v-card>
        <v-card-title>
            {{ $t('console.dashboard.projectCard.title') }}
        </v-card-title>
        <div v-if="projectName">
            <v-divider></v-divider>
            <v-simple-table dense>
                <tbody>
                    <tr>
                        <th colspan="2" :style="tableStyles.sectionHeader">
                            {{ $t('console.dashboard.projectCard.table.info.title') }}
                        </th>
                    </tr>
                    <tr>
                        <th :style="tableStyles.rowHeader">{{ $t('console.dashboard.projectCard.table.info.projectName') }}</th>
                        <td>{{ projectName }}</td>
                    </tr>
                    <!--<tr>
                        <th :style="tableStyles.rowHeader">{{ $t('console.dashboard.projectCard.table.info.description') }}</th>
                        <td></td>
                    </tr>-->

                    <tr>
                        <th colspan="2" :style="tableStyles.sectionHeader">
                            {{ $t('console.dashboard.projectCard.table.taskUrls.title') }}
                        </th>
                    </tr>
                    <tr>
                        <th :style="tableStyles.rowHeader">{{ $t('console.dashboard.projectCard.table.production.title') }}</th>
                        <td v-if="projectHasDiff" :style="tableStyles.cell">
                            <span class="warning--text font-weight-medium">
                                {{ $t('console.dashboard.projectCard.table.production.rebuildPrompt') }}
                            </span>
                            <v-btn
                                outlined
                                small
                                class="ml-2"
                                color="warning"
                                :loading="rebuildingProject"
                                @click="rebuildProject"
                            >{{ $t('console.dashboard.projectCard.table.production.rebuildButton') }}</v-btn>
                        </td>
                        <td v-else :style="tableStyles.cell">
                            <div v-if="alert.show">
                                <v-icon left color="error">mdi-alert</v-icon>
                                <span class="error--text font-weight-medium">{{ alert.message }}</span>
                            </div>
                            <div v-else class="d-flex align-center">
                                <span class="mr-4 text-caption" v-text="url"></span>
                                <copy-to-clipboard-btn x-small class="ml-1" :text="url"></copy-to-clipboard-btn>
                                <v-btn dark small class="mx-2" color="indigo darken-2" @click="openUrlInNewTab(url)">
                                    {{ $t('console.dashboard.projectCard.table.startTaskButton') }}
                                    <v-icon right>mdi-open-in-new</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t('console.dashboard.projectCard.table.development.title') }}</th>
                        <td :style="tableStyles.cell">
                            <div class="d-flex align-center">
                                <span class="mr-4 text-caption" v-text="devUrl"></span>
                                <copy-to-clipboard-btn
                                    x-small
                                    class="ml-1"
                                    :text="devUrl"
                                ></copy-to-clipboard-btn>
                                <v-btn dark small class="mx-2" color="grey darken-2" @click="openUrlInNewTab(devUrl)">
                                    {{ $t('console.dashboard.projectCard.table.startTaskButton') }}
                                    <v-icon right>mdi-open-in-new</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
        <v-card-text v-if="!projectName">
            {{ $t('console.dashboard.projectCard.selectProjectPrompt') }}
        </v-card-text>
    </v-card>
</template>
<script>
import { getUrl } from "@/lib/tutti-env.js"
import CopyToClipboardBtn from "@/components/ui/CopyToClipboardBtn"
import { openUrlInNewTab } from '@/lib/utils'

export default {
    components: {
        CopyToClipboardBtn,
    },
    data: () => ({
        projectHasDiff: false,
        rebuildingProject: false,
        alert: {
            show: false,
            message: ''
        },
        tableStyles: {
            sectionHeader: {
                backgroundColor: '#eee',
            },
            rowHeader: {
                width: '150px',
                paddingTop: '5px',
                paddingBottom: '5px',
            },
            cell: {
                paddingTop: '5px',
                paddingBottom: '5px',
            }
        },
    }),
    props: [ 'projectName', 'client' ],
    computed: {
        url() { return getUrl(this.projectName); },
        devUrl() { return getUrl(this.projectName, true); },
    },
    methods: {
        openUrlInNewTab,
        async checkProjectDiff(){
            if(this.projectName) {
                try {
                    this.projectHasDiff = await this.client.resource.checkProjectDiff({ project_name: this.projectName });
                } catch (e) {
                    if(e.code===this.client.ERROR.SYSTEM_BUILD.CONNECTION_FAILURE){
                        this.alert.show = true;
                        this.alert.message = this.$t('console.dashboard.projectCard.rebuildCheckFailure');
                    }
                }
            }
        },
        async rebuildProject(){
            this.rebuildingProject = true;
            try {
                await this.client.resource.rebuildProject({ project_name: this.projectName });
                this.$root.$emit('tutti-snackbar', 'success', this.$t('console.dashboard.projectCard.rebuildCheckFailure'));
                this.projectHasDiff = false;
            } catch (e) {
                this.$root.$emit('tutti-snackbar', 'error', this.$t('console.dashboard.projectCard.rebuildCheckFailure'));
            } finally {
                this.rebuildingProject = false;
            }
        }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.checkProjectDiff();
        });
    },
    watch: {
        projectName() {
            this.checkProjectDiff();
        }
    }
}
</script>
