var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v(
          " " + _vm._s(_vm.$t("console.dashboard.projectCard.title")) + " "
        ),
      ]),
      _vm.projectName
        ? _c(
            "div",
            [
              _c("v-divider"),
              _c("v-simple-table", { attrs: { dense: "" } }, [
                _c("tbody", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        style: _vm.tableStyles.sectionHeader,
                        attrs: { colspan: "2" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "console.dashboard.projectCard.table.info.title"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("tr", [
                    _c("th", { style: _vm.tableStyles.rowHeader }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "console.dashboard.projectCard.table.info.projectName"
                          )
                        )
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.projectName))]),
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      {
                        style: _vm.tableStyles.sectionHeader,
                        attrs: { colspan: "2" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "console.dashboard.projectCard.table.taskUrls.title"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("tr", [
                    _c("th", { style: _vm.tableStyles.rowHeader }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "console.dashboard.projectCard.table.production.title"
                          )
                        )
                      ),
                    ]),
                    _vm.projectHasDiff
                      ? _c(
                          "td",
                          { style: _vm.tableStyles.cell },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "warning--text font-weight-medium",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "console.dashboard.projectCard.table.production.rebuildPrompt"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  outlined: "",
                                  small: "",
                                  color: "warning",
                                  loading: _vm.rebuildingProject,
                                },
                                on: { click: _vm.rebuildProject },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "console.dashboard.projectCard.table.production.rebuildButton"
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("td", { style: _vm.tableStyles.cell }, [
                          _vm.alert.show
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", color: "error" } },
                                    [_vm._v("mdi-alert")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "error--text font-weight-medium",
                                    },
                                    [_vm._v(_vm._s(_vm.alert.message))]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("span", {
                                    staticClass: "mr-4 text-caption",
                                    domProps: { textContent: _vm._s(_vm.url) },
                                  }),
                                  _c("copy-to-clipboard-btn", {
                                    staticClass: "ml-1",
                                    attrs: { "x-small": "", text: _vm.url },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        dark: "",
                                        small: "",
                                        color: "indigo darken-2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openUrlInNewTab(_vm.url)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "console.dashboard.projectCard.table.startTaskButton"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("mdi-open-in-new"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]),
                  ]),
                  _c("tr", [
                    _c("th", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "console.dashboard.projectCard.table.development.title"
                          )
                        )
                      ),
                    ]),
                    _c("td", { style: _vm.tableStyles.cell }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("span", {
                            staticClass: "mr-4 text-caption",
                            domProps: { textContent: _vm._s(_vm.devUrl) },
                          }),
                          _c("copy-to-clipboard-btn", {
                            staticClass: "ml-1",
                            attrs: { "x-small": "", text: _vm.devUrl },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                dark: "",
                                small: "",
                                color: "grey darken-2",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openUrlInNewTab(_vm.devUrl)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "console.dashboard.projectCard.table.startTaskButton"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-open-in-new"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.projectName
        ? _c("v-card-text", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("console.dashboard.projectCard.selectProjectPrompt")
                ) +
                " "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }